module.exports = {
  and: 'and',
  dashboard: 'Dashboard',
  dashboardSubtitle:
    'Dashboards allow you to track, inform and analyze your cloud spend in a structured way.',
  dashboardName: 'Dashboard name',
  dashboardNameDescription: 'Name your dashboard for better identification :',
  dashboardDescription: 'Description',
  dashboardCreatedBy: 'Created by',
  dashboardDescriptionDescription:
    'Add a description to define the content of your dashboard (optional):',
  saveDashboard: 'Save the dashboard',
  saveDashboardDescription:
    'Save the dashboard before you can add content to it:',
  emptyDashboardTitle: 'Is your dashboard empty?',
  emptyDashboardDescription:
    'Click on the button below to add your first tile.',
  emptyDashboardActionTitle: 'Add a tile',
  createNewTile: 'Create a new tile',
  createNewDashboard: 'Create a dashboard',
  noDashboardFound: "Don't you have a dashboard yet?",
  noResultSearch: 'No results found for your search.',
  noDashboardDescription: 'No description',
  tagCoveringLabel: 'Tag covering',
  uncoveredSpendsLabel: 'Uncovered expenses',
  totalCostWithTag: 'Tagged expenses',
  totalCostWithoutTag: 'Unlabelled expenditure',
  trendUp: 'Your expenses are up compared to last month',
  trendDown: 'Your expenses are down compared to last month',
  countResourcesIdWithTags: 'Number of tagged resources',
  countResourcesIdWithoutTags: 'Number of unlabelled resources',
  confirmDashboardDeletion: 'Do you really want to delete this dashboard?',
  noDashboardFoundDescription:
    'Click on the button below to create a dashboard.',
  saveTile: 'Save the tile',
  saveTileDescription:
    'Click on the button below to save the tile, it will be automatically added to the selected dashboards.',
  selectGranularity: 'Select granularity',
  selectFilter: 'Select one or multiple filters',
  selectFilterDescription:
    'Choose one or more of your filters or create a new one :',
  selectBudget: 'Select a budget',
  selectBudgetDescription:
    'Choose one of your budgets from the following list:',
  selectDataToDisplay: 'Select the data',
  selectDataToDisplayDescription:
    'Choose the values to be displayed from the following list:',
  selectRepresentationMode: 'Selection of the representation',
  selectRepresentationModeDescription:
    'Choose a representation mode from the following choices:',
  selectPeriodicity: 'Select the periodicity',
  selectPeriodicityDescription:
    'Define the period over which the tile will be calculated',
  preview: 'Preview',
  previewDescription:
    'Check the display and information of the tile before adding it to a dashboard:',
  addToDashboards: 'Add to dashboard(s)',
  addToDashboardsDescription:
    'Choose one or more dashboards in which to add this tile',
  currentBudgetPeriod: 'Current period',
  previousBudgetPeriod: 'Previous period',
  totalBudgetPeriod: 'All periods',
  selectSection: 'Select a section',
  selectSectionDescription:
    'Select a section type you wish to add to your dashboard',
  sectionWithTitle: 'Title section',
  selectSectionColor: 'Define section color',
  selectSectionColorDescription:
    'Customize your dashboard by setting a section color',
  sectionTitlePlaceholder: 'Section title',
  sectionDescriptionPlaceholder: 'Section description',
  sectionTitle: 'Add a title',
  sectionTitleDescription: 'Add a title to this section (mandatory)',
  sectionDescription: 'Add a description',
  sectionDescriptionDescription: 'Give this section a description (optional)',
  name: 'Dashboard name',
  nameDescription: 'Give your new dashboard a name to better identify it',
  tileName: 'Tile name',
  tileNameDescription: 'Give your new tile a name to better identify it',
  tileDescription: 'Tile description',
  tileDescriptionDescription: 'Describe the content of your tile (optional)',
  searchDashboards: 'Search a dashboard',
  totalCost: 'Total cost (excl. VAT)',
  yourOptimizations: 'Your resources optimizations',
  yourOptimizationsDescription: 'Save money today by using our scanners',
  yearlySavings: 'Potential savings 12 months',
  unusedResources: 'Unused resources',
  oversizedResources: 'Over sized resources',
  reservableResources: 'Reservable resources',
  totalResources: 'Total',
  availableSoon: 'Available soon !',
  from: 'From',
  to: 'to',
  periodicity: {
    this_month: 'Current month',
    last_month: 'Previous month',
    last_7_days: 'Last 7 days',
    last_30_days: 'Last 30 days',
    last_3_months: 'Last 3 months',
    last_6_months: 'Last 6 months',
    this_year: 'This year',
    last_year: 'Last year',
    total: 'Total',
  },
  groupBy: {
    provider: 'Per provider',
    category: 'Per category',
    product: 'Per products',
    usage_account_id: 'Per account',
    region: 'Per region',
    tags: 'Per tag',
    resource_id: 'Per resource',
    usage_type: 'Per usage',
  },
  granularity: {
    day: 'Per day',
    week: 'Per week',
    month: 'Per month',
    quarter: 'Per quarter',
    year: 'Per year',
  },
  chartTypes: {
    lineChart: 'Row',
    columnChart: 'Column',
    areaChart: 'Area',
  },
  maxFilters: 'You have reached the maximum number of filters',
  noData: 'No data available',
  cost: 'Cost',
}
