module.exports = {
  removeAllFilters: 'Effacer tous les filtres ',
  filters: 'Filtres',
  appliedFilters: 'Filtre appliqué',
  addFilters: 'Ajouter des filtres ',
  showAllFilters: 'Afficher tous les filtres',
  withTagsModeLabel: 'Dépenses étiquetées',
  withoutTagsModeLabel: 'Dépenses non étiquetées',
  showLessFilters: 'Afficher moins de filtres',
  validateFilters: 'Appliquer les filtres',
  providers: 'Fournisseurs',
  provider: 'Fournisseur',
  products: 'Produits',
  product: 'Produit',
  accounts: 'Comptes',
  account: 'Compte',
  regions: 'Régions',
  region: 'Région',
  categories: 'Catégories',
  category: 'Catégorie',
  tags: 'Étiquettes',
  tag: 'Étiquette',
  resources: 'Ressources',
  resourceId: 'Ressources',
  costCenter: 'Centre de coûts',
  caseSensitiveSearch: 'Sensible à la casse',
  caseInsensitiveSearch: 'Insensible à la casse',
  usageAccountId: 'Comptes',
  clickToAddFilter: "Cliquez sur un filtre pour l'ajouter",
  selectFilterScope: 'Sélectionnez un critère de filtre sur le côté gauche',
  noFilterItems: 'Aucun filtre disponible',
  noFiltersSelected: 'Aucun filtre appliqué',
  groupByKeys: 'Grouper par clés',
  andOperator: "Utiliser l'opérateur ET",
  service: 'Services',
  filtersBlocks: 'Blocs de filtres',
  block: 'Bloc',
  openFilterBuilder: 'Ouvrir le constructeur de filtre',
  blocks: {
    variable: 'Variable',
    variablePlaceholder: 'Sélectionnez une variable',
    values: 'Valeurs',
    valuesPlaceholder: 'Sélectionnez au moins une valeur',
    operation: 'Opération',
    operationPlaceholder: 'Sélectionnez une opération',
    inOperation: 'Inclus les valeurs',
    ninOperation: 'Exclus les valeurs',
    eqOperation: 'Égal à',
    neqOperation: 'Différent de',
    inOperationChip: 'inclus les valeurs',
    ninOperationChip: 'exclus les valeurs',
    eqOperationChip: 'est égal à',
    neqOperationChip: 'est différent de',
    addCriterias: 'Ajouter des critères au filtre',
    andOperator: 'ET',
    orOperator: 'OU',
  },
  dialog: {
    title: 'Filtrer les données',
    titleSave: 'Enregistrer le filtre',
    titleLoad: 'Charger un filtre',
    titleExport: 'Exporter les données',
    subtitle: 'Choisir les critères du filtre',
    subtitleSave: 'Vérifier les conditions :',
    subtitleLoad: 'Sélectionner un filtre :',
    subtitleExport: 'Choisir les données à exporter',
    saveName: 'Nommer le filtre :',
    filterName: 'Nom du filtre',
    button: {
      add: 'Ajouter des filtes',
      delete: 'Effacer tous les filtres',
      close: 'Fermer',
      save: 'Appliquer',
      saveFilter: 'Enregistrer',
      loadFilter: 'Charger',
      exportData: 'Exporter les données',
    },
  },
  variables: {
    provider: 'Fournisseur',
    providerPlural: 'Fournisseurs',
    product_code: 'Produit',
    product_codePlural: 'Produits',
    service_code: 'Service',
    service_codePlural: 'Services',
    usage_account_id: 'Compte',
    usage_account_idPlural: 'Comptes',
    region: 'Région',
    regionPlural: 'Régions',
    category: 'Catégorie',
    categoryPlural: 'Catégories',
    tags: 'Étiquette',
    tagsPlural: 'Étiquettes',
    tag: 'Étiquette',
    tagPlural: 'Étiquettes',
    usage_type: 'Consommation',
    usage_quantity: 'Quantité consommée',
    usage_unit: 'Unité',
    original_cost: 'Coût HT (avant conversion)',
    conversion_rate: 'Taux de change',
    usage_typePlural: 'Consommations',
    item_type: 'Type de charge',
    item_typePlural: 'Types de charge',
    cost_center: 'Centre de coûts',
    cost_centerPlural: 'Centres de coûts',
    costCenter: 'Centre de coûts',
    costCenterPlural: 'Centres de coûts',
    resource_path: 'Ressource',
    resource_pathPlural: 'Ressources',
    cluster_id: 'Cluster',
    cluster_idPlural: 'Clusters',
    namespace: 'Espace de noms',
    namespacePlural: 'Espaces de noms',
    pod: 'Pod',
    podPlural: 'Pods',
    container: 'Conteneur',
    containerPlural: 'Conteneurs',
    labels: 'Labels',
    labelsPlural: 'Labels',
    controller_name: 'Nom du contrôleur',
    controller_namePlural: 'Noms du contrôleur',
    controller_kind: 'Type de contrôleur',
    controller_kindPlural: 'Types de contrôleur',
  },
  noResults: 'Aucun résultat trouvé pour votre recherche.',
  dimensions: {
    cloud:
      "Ce filtre permet d'intégrer vos dépenses Cloud dans la dimension (AWS, Azure, GCP, etc).",
    kubernetes:
      "Ce filtre permet d'intégrer vos dépenses Kubernetes dans la dimension (Opencost).",
    carbon:
      'Ce filtre permet d’intégrer vos émissions de C02 liées au Cloud dans la dimension.',
  },
  sortBy: {
    title: 'Trier par',
    name: 'Nom',
    id: 'Date de création',
  },
}
