module.exports = {
  name: 'Nom du budget',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  periodicity: 'Periodicité',
  allocatedCost: 'Montant Alloué',
  realizedAndAllocatedCost: 'Montant réalisé / alloué',
  realizedCost: 'Montant  Réalisé',
  reforecastedCost: 'Montant Réévalué',
  status: 'Statut',
  progress: 'Progression',
  search: 'Rechercher',
  modify: 'Modifier',
  delete: 'Supprimer',
  addToGroup: 'Ajouter à un groupe',
  renew: 'Renouveler ce budget',
  draft: 'Brouillon',
  paused: 'En pause',
  doing: 'En cours',
  done: 'Terminé',
  warning: 'Attention',
  confirmDelete: 'Voulez-vous vraiment supprimer ce budget ?',
  cancel: 'Annuler',
  close: 'Fermer',
  save: 'Enregister',
  newBudget: 'Nouveau budget',
  modifyBudget: 'Modifier le budget',
  moreOptions: "Plus d'options",
  saveAsDraft: 'Enregistrer comme brouillon',
  budgetScope: 'Cible du budget',
  filterScope: 'Cible à filtrer',
  searchFilter: 'Rechercher un filtre',
  budgetCostAllocation: 'Allocation du budget',
  PredefinedBudgetCostAllocation:
    'Répartition prédéfinie des coûts budgétaires',
  targetAllocatedCost: 'Montant alloué cible',
  periodAllocatedCost: 'Montant alloué',
  budgetAllocationDescription:
    "Par défaut, l'allocation de votre budget pour chaque période est proportionnelle au nombre de période.",
  periodTableDescription:
    "Sélectionnez une ligne dans le tableau pour mettre à jour le budget alloué d'une période.",
  finalAllocatedCost: 'Montant alloué final',
  dayPeriodicity: 'Journalière',
  monthPeriodicity: 'Mensuelle',
  yearPeriodicity: 'Annuelle',
  validateAllAllocatedCost: 'Valider toutes les périodes',
  allocatedCostTargetDiff: ' vs cible',
  realizedCostVsAllocatedCost: 'Montant réalisé vs montant alloué',
  periodTitle: 'Ajustement du budget par périodes',
  period: 'Période',
  detailTableTitle: 'Détail des périodes',
  enableNotifications: "Me notifier par e-mail de l'avancement du budget",
  budgetBalance: 'Dépenses à date vs cible',
  forcatedExpenses: 'Dépenses projetées en fin de budget',
  budgetTrend: 'Tendance budget',
  forecastedCosts: 'Projection des coûts',
  ended: 'terminé',
  budgetTotallyConsumedIn: 'Le budget sera totalement consommé en ',
  budgetTotallyConsumedOn: 'Le budget sera totalement consommé le ',
  monthEarlier: 'mois plus tôt',
  daysEarlier: 'jours plus tôt',
  inProgress: 'en cours',
  upcoming: 'à venir ',
  description: 'Description',
  addDescription: 'Ajouter une description',
  seeInExplorer: "Voir dans l'explorateur",
  modifyDescription: 'Modifier la description',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'E-mail',
  shareBudget: 'Partager ce budget',
  copy: 'Copier le lien ',
  copied: 'Le lien a été copié !',
  failedCopy: 'Erreur dans la copie du lien',
  authorizedUsers: 'Liste de vos utilisateurs autorisés',
  allowReading: 'Autoriser la lecture pour certains utilisateurs',
  readOnly: 'Lecture seule',
  createANewViewer: 'Créer un nouvel utilisateur',
  noAuthorizedUsers: 'Aucun utilisateur autorisés ',
  nameIsRequired: 'Le nom est requis ',
  emailIsRequired: "l'E-mail est requis",
  emailMustBeValid: "l'E-mail doit être valide",
  viewerNotCreated: "L'utilisateur n'a pas été créé",
  emailAlreadyInUse: 'Cet email est déjà utilisé',
  from: 'Début',
  to: 'Fin',
  year: 'Année',
  responsible: 'Responsable',
  modifyResponsible: 'Modifier le responsable',
  filterMonth: 'Filtrer par mois',
  trend: 'Tendance',
  noUserFound: 'Aucun utilisateur trouvé',
  dateCantBeUpdated:
    'Les dates de début et de fin ainsi que la périodicité du budget ne pourront être modifiées après sa création.',
  exportYourBudgets: 'Exporter vos budgets',
  exportOptions: "Options de l'export",
  exportBudgetsOnly: 'Exporter les budgets uniquement (CSV)',
  exportWithPeriods: 'Exporter les périodes uniquement (CSV)',
  exportWithFilters: 'Exporter avec les filtres',
  delimiter: 'Délimiteur',
  decimalSeparator: 'Séparateur de décimale',
  dot: 'Point .',
  comma: 'Virgule ,',
  tilde: 'Tilde ~',
  semicolon: 'Point-virgule ;',
  warningOption:
    'Attention, vous avez choisi le même symbole de délimiteur et séparation de décimale !',
  currentMonth: 'Ce mois-ci',
  lastMonth: 'Mois dernier',
  lastThreeMonth: '3 mois',
  lastSixMonth: '6 mois',
  thisYear: 'Cette année',
  lastYear: "L'année dernière",
  nextYear: "L'année prochaine",
  last2Years: 'Les deux dernières années',
  selectTimeRange: 'Sélectionner des dates',
  noBudgets: "Vous n'avez pas de budget pour l'instant",
  noBudgetGroups: "Vous n'avez pas de groupe de budgets pour l'instant.",
  loadingBudgets: 'Chargement des budgets...',
  loadingGroups: 'Chargement des groupes...',
  loadingText: 'Chargement en cours...',
  budgets: 'Budgets',
  budgetsGroups: 'Groupes de budgets',
  periodSettings: 'Paramétrage des périodes',
  reforecastPeriods: 'Réévaluer les periodes',
  reforecastedAt: 'Budget réévalué le ',
  createdAt: 'Budget créé le ',
  afterReforecast: 'après réévaluation',
  OK: 'Tout est au vert',
  WATCH: 'Budget à surveiller',
  ALERT: 'Budget en dépassement !',
  consumption: 'Consommation totale',
  negativeDifference: 'Montant restant',
  positiveDifference: 'Dépassement de',
  negativeDifferenceDescription: "à consommer jusqu'au",
  positiveDifferenceDescription: "jusqu'au",
  percentageDifferenceDescription:
    "Entrez un pourcentage d'écart entre la première et la dernière période",
  on: 'sur',
  allocated: 'alloués',
  responsibleTitle: 'Sélectionnez un utilisateur responsable.',
  optimizableDescription: 'Optimisable',
  metricDescrition: 'Avec métrique',
  responsibleDescription:
    "La personne responsable va recevoir un e-mail l'invitant à prendre en charge ce budget et sera notifiée des dépassements liés à votre budget.",
  autoFilter: '*Automatiquement créé à partir des filtres sélectionnés',
  filter: 'Filtre',
  dateDescription:
    'Sélectionnez une date de début et de fin pour votre budget.',
  periodicityDescription:
    'La périodicité permet de calculer le nombre de périodes associées à votre budget.',
  descriptionDescription: 'Vous pouvez ajouter une description à votre budget.',
  filterName: 'Nom du filtre',
  summary: 'Résumé du budget',
  dates: 'Dates',
  shareText:
    "En partageant ce budget avec d'autres utilisateurs de votre entreprise, ils pourront y accéder et voir le budget. Cependant, cela ne leur permet pas de le modifier.",
  percentageDescription:
    ' Allouez un pourcentage du budget global à lisser sur les périodes',
  customCursorChartDescription:
    'Ajustez les périodes en glissant les colonnes du graphique',
  checkBoxCustomCart: 'Respecter le montant total alloué',
  edit: 'Cliquez pour modifier',
  modificationsDone: 'Modifications effectuées',
  valueIsRequired: 'Une valeur est requise',
  invalidNumber: 'Valeur invalide',
  periodDescription: 'Cliquez sur un montant alloué pour le modifier',
  consommationDescription: 'Consommation',
  vsAllocated: 'Prévues vs allouées',
  exceeding: 'Dépassement',
  remaining: 'Restant',
  forecasted: 'Prévisionnel',
  budgetsGroup: 'Groupe de budgets',
  budgetsGroupDescription:
    'Vous pouvez ajouter ce budget à un groupe de budgets',
  noBudgetsGroup: 'Aucun groupe de budgets sélectionné',
  groupName: 'Nom du groupe',
  renewalProfilCheckbox: 'Conserver le profil de dépenses du budget parent',
  or: 'Ou',
  budgetProfile: 'Choisissez un budget de référence',
  noBudgetProfile: 'Aucun profil de budget sélectionné',
  noScopeSelected: 'Aucun filtre sélectionné',
  budgetStart: 'Début du budget',
  budgetEnd: 'Fin du budget',
  budget: 'budget',
  profils: 'Profils',
  linear: 'Linéaire',
  upward: 'Croissant',
  downward: 'Décroissant',
  linearProfile: 'Profil linéaire',
  customizableProfile: 'Profil personnalisable',
  existingBudget: "À partir d'un autre budget",
  targetBudget: 'Budget cible',
  budgetSelect: 'Veuillez sélectionner un budget',
  getBudgetProfile: 'Voulez-vous récupérer le profil du budget',
  registeredBudget: 'Budget enregistré!',
  allocatedAmount: 'Montant alloué',
  budgetSummary: 'Résumé du budget',
  budgetSelected: 'Budget sélectionné',
  budgetsSelected: 'Budgets sélectionnés',
  budgetSave: 'Enregistrement du budget en cours',
}
