module.exports = {
  removeAllFilters: 'Clear all filters',
  filters: 'Filters',
  appliedFilters: 'Applied filter',
  addFilters: 'Add filters',
  showAllFilters: 'Show all filters',
  withTagsModeLabel: 'Tagged expenses',
  withoutTagsModeLabel: 'Not tagged expenses',
  showLessFilters: 'Show less filters',
  validateFilters: 'Apply filters',
  providers: 'Providers',
  provider: 'Provider',
  products: 'Products',
  product: 'Product',
  accounts: 'Accounts',
  account: 'Account',
  regions: 'Regions',
  region: 'Region',
  categories: 'Categories',
  category: 'Category',
  tags: 'Tags',
  tag: 'Tag',
  resources: 'Resources',
  costCenter: 'Cost Center',
  resourceId: 'Resource ID',
  caseSensitiveSearch: 'Case sensitive',
  caseInsensitiveSearch: 'Case insensitive',
  groupByKeys: 'Group by keys',
  andOperator: 'Use AND operator',
  clickToAddFilter: 'Click on a filter to add it',
  selectFilterScope: 'Select a filter criteria on the left',
  noFilterItems: 'No available filter',
  noFiltersSelected: 'No filter applied',
  usageAccountId: 'Accounts',
  service: 'Services',
  filtersBlocks: 'Filters Blocks',
  block: 'Block',
  openFilterBuilder: 'Open the filter builder',
  blocks: {
    variable: 'Variable',
    variablePlaceholder: 'Select a variable',
    values: 'Values',
    valuesPlaceholder: 'Select at least one value',
    operation: 'Operator',
    operationPlaceholder: 'Select an operator',
    inOperation: 'Include values',
    ninOperation: 'Exclude values',
    eqOperation: 'Equal to',
    neqOperation: 'Different from',
    inOperationChip: 'include values',
    ninOperationChip: 'exclude values',
    eqOperationChip: 'is equal to',
    neqOperationChip: 'is different from',
    addCriterias: 'Add criteria to the filter',
    andOperator: 'AND',
    orOperator: 'OR',
  },
  dialog: {
    title: 'Filter data',
    titleSave: 'Save the filter',
    titleLoad: 'Load a filter',
    titleExport: 'Export the data',
    subtitle: 'Select the filters criteria:',
    subtitleSave: 'Check the filters:',
    subtitleLoad: 'Select a filter:',
    subtitleExport: 'Select the data to export',
    saveName: 'Name the filter:',
    filterName: 'Filter name',
    button: {
      add: 'Add filters',
      delete: 'Erase all filters',
      close: 'Close',
      save: 'Apply',
      saveFilter: 'Save',
      loadFilter: 'Load',
      exportData: 'Export data',
    },
  },
  variables: {
    provider: 'Provider',
    providerPlural: 'Providers',
    product_code: 'Product',
    product_codePlural: 'Products',
    service_code: 'Service',
    service_codePlural: 'Services',
    usage_account_id: 'Account',
    usage_account_idPlural: 'Accounts',
    region: 'Region',
    regionPlural: 'Regions',
    category: 'Category',
    categoryPlural: 'Categories',
    tags: 'tags',
    tagsPlural: 'Tags',
    tag: 'Tags',
    tagPlural: 'Tags',
    usage_type: 'Usage type',
    usage_quantity: 'Usage quantity',
    usage_unit: 'Unité',
    original_cost: 'Cost without taxes (before conversion)',
    conversion_rate: 'Conversion rate',
    usage_typePlural: 'Usage types',
    item_type: 'Charge type',
    item_typePlural: 'Charge types',
    cost_center: 'Cost center',
    cost_centerPlural: 'Cost centers',
    costCenter: 'Cost center',
    costCenterPlural: 'Cost centers',
    resource_path: 'Resource',
    resource_pathPlural: 'Resources',
    cluster_id: 'Cluster',
    cluster_idPlural: 'Clusters',
    namespace: 'Namespace',
    namespacePlural: 'Namespaces',
    pod: 'Pod',
    podPlural: 'Pods',
    container: 'Container',
    containerPlural: 'Containers',
    labels: 'Labels',
    labelsPlural: 'Labels',
    controller_name: 'Controller name',
    controller_namePlural: 'Controller names',
    controller_kind: 'Controller kind',
    controller_kindPlural: 'Controller kinds',
  },
  noResults: 'No results found',
  dimensions: {
    cloud:
      'Thjs filter allows you to integrate your Cloud expenses into the dimension (AWS, Azure, GCP, etc).',
    kubernetes:
      'This filter allows you to integrate your Kubernetes expenses into the dimension (Opencost).',
    carbon:
      'This filter allows you to integrate your carbon emissions into the dimension.',
  },
  sortBy: {
    title: 'Sort by',
    name: 'Name',
    id: 'Creation date',
  },
}
