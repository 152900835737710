import moment from 'moment'

const defaultStartDate = moment().subtract(14, 'day').format('YYYY-MM-DD')
const defaultEndDate = moment().format('YYYY-MM-DD')

const state = () => ({
  cloud: {
    group: {
      name: 'provider',
      display_name: '',
    },
    groups: [],
    parents: [],
    filter: {
      name: null,
      id: null,
      operator: 'or',
      operators: [],
    },
  },
  kubernetes: {
    group: {
      name: 'namespace',
      display_name: '',
    },
    groups: [],
    parents: [],
    filter: {
      name: null,
      id: null,
      operator: 'or',
      operators: [],
    },
  },
  carbon: {
    group: {
      name: 'provider',
      display_name: '',
    },
    groups: [],
    parents: [],
    filter: {
      name: null,
      id: null,
      operator: 'or',
      operators: [],
    },
  },
  dimension: {
    group: {
      name: 'provider',
      display_name: '',
    },
    groups: [],
    parents: [],
    filter: {
      name: null,
      id: null,
      operator: 'or',
      operators: [],
    },
  },
  chart: {
    type: 'column',
    granularity: 'day',
    logarithmic: true,
  },
  startDate: defaultStartDate,
  endDate: defaultEndDate,
})

const getters = {
  filter: (state) => (source) => {
    return state[source].filter
  },
  filtersAsJson: (state) => (source) => {
    return state[source].filter.operators.length > 0
      ? JSON.stringify([
          {
            operator: state[source].filter.operator,
            operators: state[source].filter.operators.map(
              ({ operator, column, values }) => {
                return { operator, column, values }
              },
            ),
          },
        ])
      : ''
  },
  filtersMap: (state) => (source) => {
    return state[source].groups.reduce((acc, group) => {
      acc[group.name] = group.display_name
      return acc
    }, {})
  },
  period: (state) => {
    // calculate previous start date
    // based on current start date
    // and difference between start and end date
    const diff = moment(state.endDate).diff(state.startDate, 'days')
    const previousStartDate = moment(state.startDate).subtract(diff, 'days')
    const previousEndDate = moment(state.endDate).subtract(diff, 'days')

    return {
      startDate: state.startDate,
      endDate: state.endDate,
      previousStartDate,
      previousEndDate,
    }
  },
  group: (state) => (source) => {
    return state[source].group
  },
  groups: (state) => (source) => {
    return state[source].groups
  },
  groupDisplayName: (state) => (source) => {
    return state[source].group.display_name
  },
  parents: (state) => (source) => {
    return state[source].parents
  },
  chartGranularity: (state) => {
    return state.chart.granularity
  },
  chartType: (state) => {
    return state.chart.type
  },
  chartLogarithmic: (state) => {
    return state.chart.logarithmic
  },
}

const mutations = {
  SET_FILTER_OPERATORS(state, { source, value }) {
    state[source].filter.operators = value
  },
  SET_FILTER_OPERATOR(state, { source, value }) {
    state[source].filter.operator = value
  },
  SET_FILTER_NAME(state, { source, value }) {
    state[source].filter.name = value
  },
  SET_FILTER_ID(state, { source, value }) {
    state[source].filter.id = value
  },
  DELETE_FILTER_OPERATOR(state, { source, index }) {
    state[source].filter.operators.splice(index, 1)
  },
  DELETE_FILTER_ID(state, { source }) {
    state[source].filter.id = null
  },
  SET_PERIOD(state, payload) {
    state.startDate = payload.startDate
    state.endDate = payload.endDate
  },
  SET_GROUP(state, { source, value }) {
    state[source].group = value
  },
  SET_GROUPS(state, { source, value }) {
    state[source].groups = value
  },
  SET_PARENTS(state, { source, value }) {
    state[source].parents = value
  },
  SET_CHART_GRANULARITY(state, value) {
    state.chart.granularity = value
  },
  SET_CHART_TYPE(state, value) {
    state.chart.type = value
  },
  TOGGLE_CHART_LOGARITHMIC(state) {
    state.chart.logarithmic = !state.chart.logarithmic
  },
}

const actions = {
  setFilterOperators({ commit }, { source, value }) {
    commit('SET_FILTER_OPERATORS', { source, value })
  },
  setFilterOperator({ commit }, { source, value }) {
    commit('SET_FILTER_OPERATOR', { source, value })
  },
  setFilterName({ commit }, { source, value }) {
    commit('SET_FILTER_NAME', { source, value })
  },
  setFilterId({ commit }, { source, value }) {
    commit('SET_FILTER_ID', { source, value })
  },
  deleteFilterOperator({ commit }, { source, index }) {
    commit('DELETE_FILTER_OPERATOR', { source, index })
  },
  deleteFilterId({ commit }, { source }) {
    commit('DELETE_FILTER_ID', { source })
  },
  setPeriod({ commit }, period) {
    commit('SET_PERIOD', period)
  },
  setGroup({ commit }, { source, value }) {
    commit('SET_GROUP', { source, value })
  },
  setGroups({ commit }, { source, value }) {
    commit('SET_GROUPS', { source, value })
  },
  setParents({ commit }, { source, value }) {
    commit('SET_PARENTS', { source, value })
  },
  setChartGranularity({ commit }, value) {
    commit('SET_CHART_GRANULARITY', value)
  },
  setChartType({ commit }, value) {
    commit('SET_CHART_TYPE', value)
  },
  toggleChartLogarithmic({ commit }) {
    commit('TOGGLE_CHART_LOGARITHMIC')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
