import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/settings',
    component: () => import('./views/SettingsView.vue'),
    children: [
      {
        path: '',
        alias: 'profile',
        name: 'profile',
        component: () => import('./components/settings/SettingProfile'),
      },
      {
        path: 'connector',
        name: 'settings-connectors',
        meta: { permission: 'connector.index' },
        component: () => import('./components/settings/SettingConnector'),
      },
      {
        path: 'connector/aws',
        name: 'aws-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('./components/settings/connectors/AWS/AwsCreate'),
      },
      {
        path: 'connector/oracle',
        name: 'oracle-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('./components/settings/connectors/oracle/OracleCreate'),
      },
      {
        path: 'connector/azure',
        name: 'azure-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('./components/settings/connectors/azure/AzureCreate'),
      },
      {
        path: 'connector/gcp',
        name: 'gcp-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('./components/settings/connectors/GCP/GcpCreate.vue'),
      },
      {
        path: 'users',
        name: 'settings-user',
        meta: { permission: 'user.index' },
        component: () => import('./components/settings/users/SettingUsers'),
      },
      {
        path: 'users/:id',
        name: 'show-user',
        meta: { permission: 'user.show' },
        component: () => import('./components/settings/users/SettingViewUser'),
      },
      {
        path: 'create-user',
        name: 'create-user',
        meta: { permission: 'user.store' },
        component: () =>
          import('./components/settings/users/SettingCreateUser'),
      },
      {
        path: 'data',
        name: 'settings-data',
        meta: { permission: 'costreport.index' },
        component: () => import('./components/settings/SettingData'),
      },
      {
        path: 'filters',
        name: 'settings-filters',
        meta: { permission: 'product.store' },
        component: () => import('./components/settings/SettingFilters'),
      },
      {
        path: 'application',
        name: 'settings-application',
        meta: { permission: 'product.store' },
        component: () => import('./components/settings/SettingApplication'),
      },
      {
        path: 'integrations',
        name: 'settings-integrations',
        meta: { permission: 'company.update' },
        component: () => import('./views/IntegrationsView.vue'),
        children: [
          {
            path: '',
            name: 'settings-integrations-index',
            meta: { permission: 'company.update' },
            component: () =>
              import('./components/settings/SettingIntegrations.vue'),
          },
          {
            path: 'jira-channels',
            name: 'settings-integrations-jira-channels',
            meta: { permission: 'company.update' },
            component: () =>
              import('./components/settings/integrations/JiraChannels.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'dashboard',
    alias: ['/', '/home'],
    path: '/dashboard',
    component: () => import('./views/HomeView.vue'),
    meta: { permission: 'home.index' },
  },
  {
    name: 'custom-dashboard',
    path: '/custom-dashboard',
    component: () => import('./views/Dashboards.vue'),
    meta: { permission: 'dashboard.index' },
    children: [
      {
        path: '',
        name: 'custom-dashboard-table',
        meta: { permission: 'dashboard.index' },
        component: () => import('./components/dashboard/DashboardList'),
      },
      {
        path: ':id',
        name: 'custom-dashboard-show',
        meta: { permission: 'dashboard.show' },
        component: () => import('./components/dashboard/DashboardContainer'),
      },
    ],
  },
  {
    path: '/dimensions',
    component: () => import('./views/dimensions/DimensionsLayout.vue'),
    children: [
      {
        name: 'dimensions',
        path: '',
        meta: { permission: 'explorer.index', drawerType: 'dimensions' },
        component: () => import('./views/dimensions/DimensionsIndexView.vue'),
      },
      {
        name: 'dimensions-type',
        path: ':type',
        meta: { permission: 'explorer.index', drawerType: 'dimensions' },
        component: () => import('./views/dimensions/DimensionsView.vue'),
      },
      {
        name: 'dimension',
        path: ':type/:id',
        meta: { permission: 'explorer.index' },
        component: () => import('./views/dimensions/DimensionView.vue'),
        children: [
          {
            name: 'dimension-overview',
            path: 'overview',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionOverview.vue'),
          },
          {
            name: 'dimension-subdimensions',
            path: 'sub-dimensions',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionSubDimensions.vue'),
          },
          {
            name: 'dimension-explorers',
            path: 'explorers',
            meta: {
              permission: 'explorer.index',
              drawerType: 'dimension',
              color: 'primary',
            },
            component: () =>
              import('./components/dimensions/DimensionExplorer.vue'),
          },
          {
            name: 'dimension-budgets',
            path: 'budgets',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionBudgets.vue'),
          },
          {
            name: 'dimension-alerts',
            path: 'alerts',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionAlerts.vue'),
          },
          {
            name: 'dimension-filters',
            path: 'filters',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionFilters.vue'),
          },
          {
            name: 'dimension-users',
            path: 'users',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionUsers.vue'),
          },
          {
            name: 'dimension-delete',
            path: 'delete',
            meta: { permission: 'explorer.index', drawerType: 'dimension' },
            component: () =>
              import('./components/dimensions/DimensionDelete.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'explorer',
    path: '/explorer',
    component: () => import('./views/ExplorerView.vue'),
    meta: { miniNavBar: true, permission: 'explorer.index' },
  },
  {
    name: 'explorers',
    path: '/explorers',
    component: () => import('./views/ExplorersLayout.vue'),
    children: [
      {
        name: 'explorers-cloud',
        path: 'cloud',
        component: () => import('./views/explorers/CloudExplorer.vue'),
        meta: { icon: 'cloud', color: 'primary' },
      },
      {
        name: 'explorers-kubernetes',
        path: 'kubernetes',
        component: () => import('./views/explorers/KubernetesExplorer.vue'),
        meta: { icon: 'kubernetes', color: 'red' },
      },
      {
        name: 'explorers-carbon',
        path: 'carbon',
        component: () => import('./views/explorers/CarbonExplorer.vue'),
        meta: { icon: 'molecule-co2', color: 'green' },
      },
    ],
    meta: { permission: 'explorer.index' },
  },
  {
    name: 'catalog',
    path: '/catalog',
    component: () => import('./views/CatalogView.vue'),
    meta: { miniNavBar: true, permission: 'explorer.index' }, // TODO: add permissions to API
  },
  {
    name: 'alerts',
    path: '/alerts',
    component: () => import('./views/Alerts.vue'),
    meta: { disabled: true, permission: 'alert.index' },
  },
  {
    name: 'alertReport',
    path: '/alert-report/:id',
    component: () => import('./views/AlertReport.vue'),
    meta: { disabled: true, permission: 'alert.show' },
  },
  {
    name: 'tags-dashboard',
    path: '/tags-dashboard',
    component: () => import('./views/TagsDashboard.vue'),
    meta: { permission: 'tags-dashboard.index' },
  },
  {
    name: 'tags-distribution',
    path: '/tags-distribution',
    component: () => import('./views/TagsDistribution'),
    meta: { permission: 'tags-dashboard.index' },
  },

  {
    name: 'tags-configuration',
    path: '/tags-configuration',
    redirect: '/internal',
    component: () => import('./views/TagsConfiguration'),
    meta: { permission: 'tags-dashboard.index' },
    children: [
      {
        name: 'internal',
        path: '/internal',
        component: () => import('./components/tags/lotaTags/InternalTagsTable'),
      },
      {
        name: 'external',
        path: '/external',
        component: () =>
          import('./components/tags/lotaTags/ProvidersTagsTable'),
      },
      {
        name: 'strategies',
        path: '/strategies',
        component: () =>
          import('./components/tags/lotaTags/strategy/StrategiesTable'),
      },
      {
        name: 'best-practices',
        path: 'best-practices',
        component: () => import('./components/tags/BestPractices'),
      },
    ],
  },
  {
    name: 'strategy',
    path: '/strategy/:id',
    component: () => import('./views/StrategyView'),
    children: [
      {
        name: 'strategy-overview',
        path: '',
        component: () =>
          import('./components/tags/lotaTags/strategy/StrategyViewTable.vue'),
      },
      {
        name: 'strategy-update',
        path: 'resources',
        component: () =>
          import(
            './components/tags/lotaTags/strategy/TagsStrategyResources.vue'
          ),
      },
    ],
    redirect: { name: 'strategy-overview' },
  },
  {
    name: 'tags-coverage',
    path: '/tags-coverage',
    component: () => import('./views/TagsList'),
    meta: { permission: 'tags-dashboard.index' },
  },
  {
    name: 'azure',
    path: '/azure',
    component: () => import('./views/SettingsView.vue'),
    meta: { permission: 'connector.store' },
  },
  {
    name: 'googleCloud',
    path: '/google',
    component: () => import('./views/SettingsView.vue'),
    meta: { permission: 'connector.store' },
  },
  {
    name: 'OracleCloud',
    path: '/oracle',
    component: () => import('./views/SettingsView.vue'),
    meta: { permission: 'connector.store' },
  },
  {
    name: 'Optimization',
    path: '/optimization',
    component: (resolve) => require(['./views/OptimizationLayout'], resolve),
    children: [
      {
        name: 'optimization-overview',
        path: '',
        component: (resolve) =>
          require(['./components/optimization/OverviewPage'], resolve),
      },
      {
        name: 'optimization-setup',
        path: 'setup',
        component: (resolve) =>
          require(['./components/optimization/Setup'], resolve),
      },
      {
        name: 'unused-resources',
        path: 'unused-resources',
        component: (resolve) => require(['./views/UnusedResources'], resolve),
      },
      {
        name: 'oversized-resources',
        path: 'oversized-resources',
        component: (resolve) =>
          require(['./views/OversizedResources'], resolve),
      },
      {
        name: 'reserved-instances',
        path: 'reserved-instances',
        component: (resolve) => require(['./views/ReservedInstances'], resolve),
      },
      {
        name: 'benefits',
        path: 'benefits',
        component: (resolve) => require(['./views/BenefitsView'], resolve),
      },
      {
        name: 'catalog',
        path: 'catalog',
        component: () => import('./views/CatalogView.vue'),
      },
    ],
    meta: { role: 'user' },
  },

  {
    name: 'setPassword',
    path: '/setPassword',
    component: () => import('./views/SetPassword.vue'),
    meta: { menu: false, navbar: false },
  },
  {
    name: 'loginSuccess',
    path: '/loginsuccess',
    component: () => import('./views/LoginSuccess.vue'),
    meta: { menu: false, navbar: false, noAuth: true },
  },

  {
    name: 'login',
    path: '/login',
    component: () => import('./views/Login.vue'),
    meta: { menu: false, navbar: false, noAuth: true },
    children: [
      {
        name: 'blocLogin',
        path: '',
        component: () => import('./components/layouts/login/BlocLogin.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocEmailValidation',
        path: 'emailValidation',
        component: () =>
          import('./components/layouts/login/BlocEmailValidation.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordForgotten',
        path: 'passwordForgotten',
        component: () =>
          import('./components/layouts/login/BlocPasswordForgotten.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordRecoverySuccess',
        path: 'passwordRecoverySuccess',
        component: () =>
          import('./components/layouts/login/BlocPasswordRecoverySuccess'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordRecoveryError',
        path: 'passwordRecoveryError',
        component: () => import('./components/layouts/login/BlocPasswordError'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
    ],
  },
  {
    name: 'recommendations',
    path: '/recommendations',
    component: () => import('./views/RecommendationsLayout.vue'),
    children: [
      {
        name: 'recommendations-list',
        path: '',
        meta: { permission: 'recommendation.index' },
        component: () =>
          import('./components/recommendations/RecommendationsList'),
      },
      {
        name: 'recommendations-create',
        path: 'create',
        meta: { permission: 'recommendation.store' },
        component: () =>
          import('./components/recommendations/RecommendationsCreate'),
      },
      {
        name: 'recommendation',
        path: ':id',
        meta: { permission: 'recommendation.show' },
        component: () => import('./components/recommendations/Recommendation'),
      },
      {
        name: 'recommendation-group',
        path: 'group/:id',
        meta: { permission: 'recommendation.index' },
        component: () =>
          import('./components/recommendations/RecommendationGroup'),
      },
    ],
  },
  {
    name: 'inventory',
    path: '/inventory',
    component: () => import('./views/InventoryView.vue'),
    children: [
      {
        name: 'inventory-empty-view',
        path: '',
        component: () =>
          import('./components/layouts/inventory/InventoryEmptyView.vue'),
      },
      {
        name: 'inventory-detail',
        path: ':id',
        component: () =>
          import('./components/layouts/inventory/InventoryDetailView.vue'),
      },
    ],
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () => import('./views/Forbidden.vue'),
    meta: { menu: false, navbar: false },
  },
  {
    name: 'error',
    path: '*',
    component: () => import('./views/Error.vue'),
    meta: { menu: false, navbar: false },
  },
  {
    name: 'budgets',
    path: '/budgets',
    alias: ['/budget'],
    component: () => import('./views/Budgets'),
    meta: { permission: 'budget.index', miniNavBar: true },
    children: [
      {
        name: 'budget-table',
        path: '',
        meta: { permission: 'budget.index' },
        components: {
          table: () => import('./components/budget/BudgetTable'),
          groupTable: () => import('./components/budget/BudgetGroupTable'),
        },
      },
      {
        name: 'budget-create',
        path: 'create',
        meta: { permission: 'budget.store' },
        component: () => import('./views/BudgetForm'),
      },
      {
        name: 'budget-renewal',
        path: 'renewal',
        component: () => import('./views/BudgetForm'),
      },
      {
        name: 'budget-update',
        path: 'update',
        component: () => import('./views/BudgetForm'),
      },
      {
        name: 'budget-group',
        path: '/budgets/group/:budgetGroupId',
        components: {
          group: () => import('./components/budget/BudgetGroupDetail'),
        },
        meta: { permission: 'budgetgroup.show', miniNavBar: true },
      },
      {
        name: 'budget',
        path: ':id',
        component: () => import('./views/BudgetView.vue'),
        children: [
          {
            name: 'overview',
            path: '',
            component: () => import('./components/budget_v2/BudgetOverview'),
          },
          {
            name: 'periods',
            path: 'periods',
            component: () => import('./components/budget_v2/BudgetPeriods'),
          },
          {
            name: 'optimizations',
            path: 'optimizations',
            component: () =>
              import('./components/budget_v2/BudgetOptimizations'),
          },
          {
            name: 'parameters',
            path: 'parameters',
            component: () => import('./components/budget_v2/BudgetSettings'),
          },
        ],
        redirect: { name: 'overview' },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
})

router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('api_token')
  const permissions = store.getters.user.permissions

  if (!token && to.path === '/forbidden') {
    return next({ path: '/login', query: { redirect: to.query.redirect } })
  }

  if (!token && to.path.startsWith('/login')) {
    return next()
  }

  if (token && to.path.startsWith('/login')) {
    return next('/dashboard')
  }

  // Check if the user has the right permissions
  if (
    to.meta.permission &&
    !permissions.includes(to.meta.permission) &&
    to.path !== '/forbidden'
  ) {
    return next({ name: 'forbidden', query: { redirect: to.path } })
  }

  next()
})

export default router
